/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import MasterLayout from "../components/MasterLayout";
import SignupView from "../components/SignupView";

const SignupPage = memo(function SignupPage(): ReactElement {
  const [{ redirectUrl, inviteToken, initialEmail, referral }] = useQueryParams(
    {
      redirectUrl: StringParam,
      inviteToken: StringParam,
      initialEmail: StringParam,
      referral: StringParam,
    },
  );

  return (
    <MasterLayout title="Signup">
      <SignupView
        redirectUrl={redirectUrl ?? undefined}
        inviteToken={inviteToken ?? undefined}
        initialEmail={initialEmail ?? undefined}
        referral={referral ?? undefined}
      />
    </MasterLayout>
  );
});
export default SignupPage;
