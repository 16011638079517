/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import { memo, ReactElement, useCallback, useState } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

import { MFV_EVENTS } from "../analytics/events";
import { Heap } from "../analytics/heap";
import { CreateUserParams } from "../http/endpoints";
import DUIInputLabel from "./DUIInputLabel";
import GoogleSignInButton from "./GoogleSignInButton";
import OrSeparator from "./OrSeparator";
import QueryLink from "./QueryLink";
import Section from "./Section";
import SsoSignInButton from "./SsoSignInButton";
import { fullWidthSx, linkSx } from "./styles";
import TermsOfService from "./TermsOfService";
import ValidatingForm from "./ValidatingForm";
interface SignupFormProps extends BoxProps {
  isSigningUp: boolean;
  errorMessage: string | undefined;
  loginUrl: string;
  initialEmail?: string;
  onSignup(params: Partial<CreateUserParams>): void;
  googleLogin(code: string): void;
}

const SignupForm = ({
  isSigningUp,
  errorMessage,
  loginUrl,
  initialEmail,
  onSignup,
  googleLogin,
  ...boxProps
}: SignupFormProps): ReactElement => {
  const [email, setEmail] = useState(initialEmail ?? "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = useCallback(() => {
    Heap.track(MFV_EVENTS.SIGNUP_ATTEMPTED, { email });
    onSignup({ email, firstName, lastName, password });
  }, [onSignup, email, firstName, lastName, password]);

  return (
    <Box {...boxProps}>
      <Section centered={true}>
        <DUIText size="2xl">Signup</DUIText>
      </Section>
      <Section shaded={true}>
        <div sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <GoogleSignInButton action="Sign up" onSuccess={googleLogin} />
          <SsoSignInButton action="Sign up" />
        </div>
        <OrSeparator my={4} />
        <ValidatingForm onSubmitPreventDefault={handleSignup}>
          <DUIInputLabel text="First Name">
            <DUIInput
              type="text"
              placeholder="Gavin"
              required={true}
              autoComplete="given-name"
              value={firstName}
              onValueChange={setFirstName}
            />
          </DUIInputLabel>
          <DUIInputLabel mt={5} text="Last Name">
            <DUIInput
              type="text"
              placeholder="Belson"
              required={true}
              autoComplete="family-name"
              value={lastName}
              onValueChange={setLastName}
            />
          </DUIInputLabel>
          <DUIInputLabel mt={5} text="Email">
            <DUIInput
              type="email"
              placeholder="gavin@hooli.com"
              autoComplete="email"
              required={true}
              value={email}
              onValueChange={setEmail}
            />
          </DUIInputLabel>
          <DUIInputLabel mt={5} mb={5} text="Password">
            <DUIInput
              type="password"
              placeholder="••••••••"
              required={true}
              minLength={10}
              autoComplete="password"
              value={password}
              onValueChange={setPassword}
            />
          </DUIInputLabel>
          <DUIButton
            mt={5}
            sx={fullWidthSx}
            size="lg"
            type="submit"
            disabled={isSigningUp}
          >
            Sign up
          </DUIButton>
          {errorMessage && (
            <DUIText size="lg" mt={3} sx={{ color: "danger.core" }}>
              {errorMessage}
            </DUIText>
          )}
          <DUIText
            mt={5}
            sx={{ textAlign: "center", fontWeight: 500 }}
            size="md"
          >
            <QueryLink sx={linkSx} to={loginUrl}>
              Return to Login
            </QueryLink>
          </DUIText>
          <TermsOfService action={"Sign up"} />
        </ValidatingForm>
      </Section>
    </Box>
  );
};

export default memo(SignupForm);
