/** @jsx jsx */
import useIdentifyUser from "@hooks/useIdentifyUser";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

import {
  createUser,
  CreateUserParams,
  googleLogin,
  GoogleLoginParams,
  LoginResult,
  MFVChallengeParams,
} from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";
import { getMfvParamsFromResult, hasMfvChallenge } from "../util/mfvUtils";
import EmailSentView from "./EmailSentView";
import HCaptchaModal from "./HCaptchaModal";
import MultiFactorVerificationModal from "./MultiFactorVerificationModal";
import SignupForm from "./SignupForm";

export interface SignupViewProps extends BoxProps {
  redirectUrl?: string;
  inviteToken?: string;
  initialEmail?: string;
  referral?: string;
}

const SignupView = ({
  redirectUrl,
  inviteToken,
  initialEmail,
  referral,
  ...boxProps
}: SignupViewProps): ReactElement => {
  const [submittedParams, setSubmittedParams] = useState<CreateUserParams>();
  const [hCaptchaModalCallback, setHCaptchaModalCallback] =
    useState<React.ComponentProps<typeof HCaptchaModal>["onVerify"]>();
  const [result, setResult] = useState(
    Loading.unloaded<Partial<LoginResult>>(),
  );
  const [mfvChallengeParams, setMfvChallengeParams] =
    useState<MFVChallengeParams>();
  const [signupParams, setSignupParams] = useState<
    CreateUserParams | GoogleLoginParams
  >();
  const [signupType, setSignupType] = useState<"Google" | "User/Pass">();
  const waitForLoad = useWaitForLoad();
  const identifyUser = useIdentifyUser();

  const isHcaptchaModalOpen = !!hCaptchaModalCallback;
  const isMFVModalOpen = !!mfvChallengeParams && !submittedParams;

  // Handles hCaptcha challenge first
  const handleSignup = useCallback(
    (params: CreateUserParams) => {
      setHCaptchaModalCallback(() => (hCaptchaToken: string) => {
        setResult(Loading.inProgress());
        const updatedParams = {
          ...params,
          redirectUrl,
          inviteToken,
          referral,
          hCaptchaToken,
          sessionId: (window as any)._sift_session_id,
        };
        waitForLoad(createUser(updatedParams), (result) => {
          if (result.value?.redirectTo) {
            // SSO Redirect
            window.location.href = result.value.redirectTo;
          } else if (result.hasValue) {
            // Signup Success
            setSubmittedParams(updatedParams);
            setResult(Loading.unloaded<Partial<LoginResult>>());
          } else if (hasMfvChallenge(result)) {
            // Failed due to needing 2FV
            setSignupParams(updatedParams);
            setSignupType("User/Pass");
            setResult(Loading.unloaded<Partial<LoginResult>>());
            setMfvChallengeParams(getMfvParamsFromResult(result));
          } else {
            // Result is an error
            setResult(result);
          }
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
    [createUser, redirectUrl, inviteToken, referral],
  );

  // Handles hCaptcha challenge first for google logins
  const handleGoogleLogin = useCallback(
    (code: string) => {
      setHCaptchaModalCallback(() => (hCaptchaToken: string) => {
        setResult(Loading.inProgress());
        const updatedParams = {
          code,
          redirectUrl,
          inviteToken,
          referral,
          hCaptchaToken,
          sessionId: (window as any)._sift_session_id,
        };
        setSignupParams(updatedParams);
        waitForLoad(googleLogin(updatedParams), (result) => {
          if (hasMfvChallenge(result)) {
            setSignupType("Google");
            setResult(Loading.unloaded<Partial<LoginResult>>());
            setMfvChallengeParams(getMfvParamsFromResult(result));
          } else {
            setResult(result);
          }
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
    [googleLogin, redirectUrl, inviteToken, referral],
  );

  const handleResend = useCallback(async () => {
    if (!submittedParams) {
      console.error("Cannot resend email that was never sent.");
      return;
    }

    setHCaptchaModalCallback(() => (hCaptchaToken: string) => {
      setResult(Loading.inProgress());
      const updatedParams = { ...submittedParams, hCaptchaToken };
      waitForLoad(createUser(updatedParams), setResult);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [submittedParams]);

  useEffect(() => {
    const redirectTo = result.value?.redirectTo;
    if (redirectTo) {
      identifyUser(() => {
        window.location.href = redirectTo;
      });
    }
  }, [identifyUser, result.value?.redirectTo]);

  return (
    <Box {...boxProps}>
      {submittedParams ? (
        <EmailSentView
          email={submittedParams.email}
          actionText="start building!"
          sendEmail={handleResend}
          result={result}
        />
      ) : (
        <SignupForm
          isSigningUp={!!result.isLoading}
          errorMessage={result.error?.message}
          loginUrl="/"
          initialEmail={initialEmail}
          onSignup={handleSignup}
          googleLogin={handleGoogleLogin}
        />
      )}

      <HCaptchaModal
        onVerify={hCaptchaModalCallback}
        isOpen={isHcaptchaModalOpen}
        onClose={() => setHCaptchaModalCallback(undefined)}
      />

      <MultiFactorVerificationModal
        isOpen={isMFVModalOpen}
        onClose={() => setMfvChallengeParams(undefined)}
        mfvChallengeParams={mfvChallengeParams}
        setResult={setResult}
        setSubmittedParams={setSubmittedParams}
        signupParams={signupParams}
        signupType={signupType}
      />
    </Box>
  );
};

export default memo(SignupView);
